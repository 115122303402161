<template>
  <b-card 
    no-body
    class="mb-0">

    <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Table.Instansi.Title') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
          </b-col>
        </b-row>

      </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
      <span
          v-if="props.column.label ==='Name'"
          class="text-nowrap"
        >
          {{ $t('Table.Instansi.Name') }}
        </span>
        <span
          v-if="props.column.label ==='Branding'"
          class="text-nowrap"
        >
          {{ $t('Table.Instansi.Branding') }}
        </span>
        <span
          v-if="props.column.label ==='Description'"
          class="text-nowrap"
        >
          {{ $t('Table.Instansi.Description') }}
        </span>
        <span
          v-if="props.column.label ==='Status'"
          class="text-nowrap"
        >
          {{ $t('Table.Instansi.Status') }}
        </span>
        <span
          v-if="props.column.label ==='Action'"
          class="text-nowrap"
        >
          {{ $t('Table.Instansi.Actions') }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'Name'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.name }}</span>
        </div>

         <div
          v-if="props.column.field === 'status'"
          class="text-nowrap"
        >
          <b-badge 
            pill
            :variant="status[1][props.row.status]">
            {{ status[0][props.row.status] }}
          </b-badge>
        </div>

        <!-- Column: Action -->
        <div class="text-nowrap" v-else-if="props.column.field === 'action'">
          <feather-icon
            icon="EyeIcon"
            size="24"
            class="mx-1 cursor-pointer"
            @click="setLocalStorageDataInstansi(props.row.id)"
          />
          <b-tooltip
            title="Detail Instansi"
            class="cursor-pointer"
            :target="`preview-icon`"
          />         
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('Table.Showing') }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{ $t('Table.Of') }} {{ props.total }} {{ $t('Table.Entries') }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BAvatar, BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moduleApi from '@/store/module'
import store from '@/store/index'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BPagination,  
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Name',
          },
        },
        {
          label: 'Branding',
          field: 'branding',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Instansi',
          },
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Description',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      status: [{
        false: 'Inactive', true: 'Active',
      },
      {
        false: 'light-danger', true: 'light-success',
      }],
      searchTerm: '',
      userData: getUserData()
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    setLocalStorageDataInstansi(id) {
      localStorage.setItem("instansi_id", id)
      this.$router.push({ name: 'instansi-product-list', params: {id: id} })
    },

    fetchDataListInstansi() { 
      moduleApi.fetchDataInstansi(this.userData.uid)
        .then(response => {
            this.rows  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
  },
  created() {
    this.fetchDataListInstansi()
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>
